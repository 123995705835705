<template>
  <!--begin::Post-->
  <div class="post d-flex flex-column-fluid" id="kt_post">
    <!--begin::Container-->
    <div id="kt_content_container" class="container-xxl">
      <!--begin::Card-->
      <div class="card">
        <!--begin::Card body-->
        <div class="card-body">
          <div v-if="message" class="alert alert-danger mb-10" role="alert">
            {{ message }}
          </div>
          <Form
            @submit="handleSubmit"
            :validation-schema="schema"
            class="form w-100"
            novalidate="novalidate"
            id="kt_sign_in_form"
          >
            <div class="mb-5">
              <Field
                type="text"
                placeholder="name"
                name="name"
                class="form-control bg-transparent"
                v-model="formInput.name"
              />
              <ErrorMessage
                name="username"
                class="text-danger error-feedback"
              />
            </div>
            <div class="mb-5">
              <Field
                accept=".docx"
                type="file"
                name="template"
                class="form-control bg-transparent"
                v-model="formInput.template"
                @change="onChangeFile"
              />
              <ErrorMessage
                name="template"
                class="text-danger error-feedback"
              />
            </div>
            <div class="mb-5">
              <Field
                as="textarea"
                name="mappingdata"
                class="form-control bg-transparent"
                v-model="formInput.mappingdata"
              />
              <ErrorMessage
                name="mappingdata"
                class="text-danger error-feedback"
              />
            </div>
            <div>
              <button
                class="btn btn-secondary"
                @click="$router.push({ name: 'card-type-list' })"
              >
                Back
              </button>
              <button
                :disabled="loading"
                type="submit"
                id="kt_sign_in_submit"
                class="btn btn-success"
              >
                <span class="indicator-label">Submit</span>
                <span class="indicator-progress" v-show="loading">
                  Please wait...
                  <span
                    class="spinner-border spinner-border-sm align-middle ms-2"
                  >
                  </span>
                </span>
              </button>
            </div>
          </Form>
        </div>
        <!--end::Card body-->
      </div>
      <!--end::Card-->
    </div>
    <!--end::Container-->
  </div>
  <!--end::Post-->
</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import Service from "../../../services/base.service";

export default {
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    const schema = yup.object().shape({
      name: yup.string().required("Name is required!"),
    });

    return {
      loading: false,
      message: "",
      formInput: { name: "", template: "", mappingdata: "" },
      file: null,
      schema,
    };
  },
  mounted() {
    if (this.$route.params.id) {
      this.fetchData();
    }
    // this.getListData();
  },
  methods: {
    async fetchData() {
      this.loading = true;
      try {
        const BaseService = new Service("card-type");
        const { data } = await BaseService.getData(this.$route.params.id);
        this.formInput = data;
        // this.message = message;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    async handleSubmit(data) {
      this.loading = true;
      try {
        if (this.file) {
          const formData = new FormData();
          formData.append("file", this.file);
          const AttachmentService = new Service("attachment/upload");
          const attachment = await AttachmentService.createData(formData);

          if (!attachment.data.id) {
            this.$swal.fire({
              icon: "error",
              title: "failed upload template file",
            });
            return;
          }
          data.template = attachment.data.id;
        }

        const BaseService = new Service("card-type");
        const { message } = this.$route.params.id
          ? await BaseService.updateData(this.$route.params.id, data)
          : await BaseService.createData(data);
        this.message = message;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    async onChangeFile(event) {
      this.file = event.target.files ? event.target.files[0] : null;
    },
  },
};
</script>
